import { render, staticRenderFns } from "./photovoltaicBox4.vue?vue&type=template&id=799d2dc0&scoped=true"
import script from "./photovoltaicBox4.vue?vue&type=script&lang=js"
export * from "./photovoltaicBox4.vue?vue&type=script&lang=js"
import style0 from "./photovoltaicBox4.vue?vue&type=style&index=0&id=799d2dc0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799d2dc0",
  null
  
)

export default component.exports