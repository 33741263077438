<template>
    <div class="Awarp">
        <dashCard class="photovoltaicBox4" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">气象信息</template>
            <template slot="aside"></template>
            <div class="card-item-warp">
                <div class="card-item" v-for="(item,idx) in detailInfo.list" :key="idx">
                    <div>{{ item.label }}</div>
                    <div>
                        {{ item.value }}<font>{{ item.unit }}</font>
                    </div>
                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <photovoltaicBox4  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'photovoltaicBox4',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {},
                detailInfo: {
                    list: []
                },
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            // 获取数据
            getData() {
                this.isLoading = true
                var list = [
                        {
                            label:"风速",
                            value: 136,
                            unit: 'm/s'
                        },
                        {
                            label:"大气温度",
                            value: 597,
                            unit: '℃'
                        },
                        {
                            label:"大气湿度",
                            value: 672,
                            unit: '%RH'
                        },
                        {
                            label:"总辐射传感器",
                            value: 597,
                            unit: 'W/m2'
                        },
                        {
                            label:"日照时数",
                            value: 84,
                            unit: 'h'
                        },
                        {
                            label:"日辐射累计",
                            value: 190.0,
                            unit: 'MJ'
                        },
                    ]
                    list.forEach(item=>{
                        item.value = this.toThousandsSeparator(item.value)
                    })


                this.detailInfo.list = list
                console.log(this.detailInfo)
                this.isLoading = false
            },
            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.photovoltaicBox4 {
    .card-item-warp {
        overflow: auto;
        margin-top: 7px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .card-item {
            width: 200px;
            margin-bottom: 40px;
            div{
                &:nth-child(1) {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0,0,0,0.65);
                    line-height: 22px;
                }
                &:nth-child(2) {
                    font-size: 36px;
                    font-weight: 700;
                    color: #1f1f1f;
                    line-height: 44px;
                    font {
                        margin-left: 6px;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(0,0,0,0.45);
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
</style>